<template>
  <div>
    <!-- class="flex flex-wrap gap-x-2 gap-y-1 -mt-2 mb-3 justify-between sm:justify-start" -->
    <SubmenuWrapper>
      <SubmenuButton v-if="showAdmin" destination="ProductList">
        <template #header>Produkty</template>
        <template #description>poziomy prowizyjne</template>
      </SubmenuButton>
      <SubmenuButton v-if="showAdmin" destination="Insurers">
        <template #header>Towarzystwa ub.</template>
      </SubmenuButton>
    </SubmenuWrapper>
    <router-view />
  </div>
</template>

<script>
import SubmenuButton from '../components/SubmenuButton.vue';

export default {
  name: 'Settings',
  components: {
    SubmenuButton,
  },
  computed: {
    currentUserType() {
      return this.$store.getters.currentUserType;
    },
    showAdmin() {
      return this.currentUserType === 'root' || this.currentUserType === 'admin';
    },
  },
  mounted() {
    if (this.$route.name === 'Settings') {
      this.$router.replace({ name: 'ProductList' });
    }
  },
};
</script>
